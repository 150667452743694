import { Container, Row, Col } from "react-bootstrap";

const ReturnPolicyScreen = () => {
  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col lg={10}>
          <h1 className="mb-4">Return Policy</h1>
          <p className="text-muted">Last updated: March 4, 2025</p>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">1. Overview</h2>
            <p>
              At Stone Cold Graphics LLC, we take great pride in the quality of our vinyl graphics and decals. We understand that there may be situations where a return or exchange is necessary. This policy outlines our procedures for returns, exchanges, and refunds.
            </p>
            <p>
              By placing an order with us, you agree to the terms of this Return Policy. Please read it carefully before making a purchase.
            </p>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">2. Custom Orders</h2>
            <p>
              Please note that due to the custom nature of our products:
            </p>
            <ul>
              <li>Custom-designed vinyl graphics and decals are made specifically for you and cannot be resold</li>
              <li>Returns or exchanges of custom orders are only accepted if there is a manufacturing defect or if the item received does not match what was ordered</li>
              <li>We strongly recommend reviewing your design proof carefully before approving it for production</li>
            </ul>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">3. Defective or Damaged Products</h2>
            <p>
              If you receive a defective or damaged product:
            </p>
            <ul>
              <li>Contact us within 48 hours of receiving your order</li>
              <li>Provide clear photos of the defect or damage</li>
              <li>We will evaluate the issue and offer a replacement, repair, or refund as appropriate</li>
              <li>Defects due to improper application or installation are not covered under our return policy</li>
            </ul>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">4. Return Process</h2>
            <p>
              To initiate a return or exchange:
            </p>
            <ol>
              <li>Contact us at gram.stone@stonecold.graphics or (813) 525-6820 to request a return authorization</li>
              <li>Explain the reason for your return and provide your order number</li>
              <li>If approved, you will receive return instructions and a return authorization number</li>
              <li>Package the item securely with all original packaging</li>
              <li>Include the return authorization number on the outside of the package</li>
              <li>Ship the item to the address provided in the return instructions</li>
            </ol>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">5. Refunds</h2>
            <p>
              Once we receive and inspect the returned item:
            </p>
            <ul>
              <li>We will notify you of the approval or rejection of your refund</li>
              <li>If approved, your refund will be processed within 5-7 business days</li>
              <li>Credit will be automatically applied to your original method of payment</li>
              <li>Depending on your payment provider, it may take an additional 2-10 business days for the refund to appear in your account</li>
            </ul>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">6. Application Guidelines</h2>
            <p>
              To ensure the best results with our products:
            </p>
            <ul>
              <li>Follow the application instructions provided with your order</li>
              <li>Clean and prepare the surface properly before application</li>
              <li>Apply vinyl graphics in appropriate weather conditions (ideally 50-90°F/10-32°C)</li>
              <li>Allow 24-48 hours for the adhesive to fully cure before washing or exposing to harsh conditions</li>
            </ul>
            <p>
              Improper application may void the ability to return or exchange the product.
            </p>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">7. Contact Information</h2>
            <p>
              If you have any questions about our Return Policy, please contact our customer service team:
            </p>
            <p>
              Email: gram.stone@stonecold.graphics<br />
              Phone: (813) 525-6820
            </p>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default ReturnPolicyScreen;