import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { FaPhone, FaEnvelope, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

// The ScrollToTop component now handles automatic scrolling for internal route changes
// External links (<a> tags) maintain their default behavior (no automatic scroll to top)
const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-dark text-white mt-5 pt-5 pb-4">
      <Container>
        <Row className="mb-4">
          <Col md={3} className="mb-4 mb-md-0">
            <h5 className="text-uppercase mb-4">Stone Cold Graphics</h5>
            <p className="mb-3">
              Premium custom vinyl graphics and decals for your vehicles, walls, and more.
              Made with high-quality materials that last for years.
            </p>
            <div className="social-icons">
              <a href="https://www.instagram.com/stone.cold.graphics813/" className="text-white fs-5" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </a>
            </div>
          </Col>
          
          <Col md={3} className="mb-4 mb-md-0">
            <h5 className="text-uppercase mb-4">Quick Links</h5>
            <ListGroup variant="flush" className="quick-links">
              <ListGroup.Item className="bg-dark text-white border-0 ps-0 py-1">
                <Link to="/" className="text-decoration-none text-white">Home</Link>
              </ListGroup.Item>
              <ListGroup.Item className="bg-dark text-white border-0 ps-0 py-1">
                <Link to="/about" className="text-decoration-none text-white">About Us</Link>
              </ListGroup.Item>
              <ListGroup.Item className="bg-dark text-white border-0 ps-0 py-1">
                <Link to="/contact" className="text-decoration-none text-white">Contact</Link>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          
          <Col md={3} className="mb-4 mb-md-0">
            <h5 className="text-uppercase mb-4">Legal</h5>
            <ListGroup variant="flush" className="quick-links">
              <ListGroup.Item className="bg-dark text-white border-0 ps-0 py-1">
                <Link to="/privacy-policy" className="text-decoration-none text-white">Privacy Policy</Link>
              </ListGroup.Item>
              <ListGroup.Item className="bg-dark text-white border-0 ps-0 py-1">
                <Link to="/terms-of-service" className="text-decoration-none text-white">Terms of Service</Link>
              </ListGroup.Item>
              <ListGroup.Item className="bg-dark text-white border-0 ps-0 py-1">
                <Link to="/return-policy" className="text-decoration-none text-white">Return Policy</Link>
              </ListGroup.Item>
              <ListGroup.Item className="bg-dark text-white border-0 ps-0 py-1">
                <Link to="/shipping-policy" className="text-decoration-none text-white">Shipping Policy</Link>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          
          <Col md={3}>
            <h5 className="text-uppercase mb-4">Contact Us</h5>
            <div className="contact-info">
              {/* <p className="d-flex align-items-center mb-3">
                <FaMapMarkerAlt className="me-2" /> 1713 Chapel Tree Cir, Tampa, FL 33511
              </p> */}
              <p className="d-flex align-items-center mb-3">
                <FaPhone className="me-2" /> (813) 525-6820
              </p>
              <p className="d-flex align-items-center mb-3">
                <FaEnvelope className="me-2" /> 
                <a href="mailto:gram.stone@stonecold.graphics" className="text-decoration-none text-white">
                  gram.stone@stonecold.graphics
                </a>
              </p>
            </div>
          </Col>
        </Row>
        
        <Row className="mt-4 pt-4 border-top border-secondary">
          <Col className="text-center">
            <p className="mb-0">
              &copy; {currentYear} Stone Cold Graphics LLC. All Rights Reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;