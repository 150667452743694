import { Container, Row, Col, Card } from "react-bootstrap";
import { FaHistory, FaUsers, FaAward } from "react-icons/fa";
import images from "../config/imageConfig";

const AboutScreen = () => {
  return (
    <Container className="py-5">
      <Row className="justify-content-center mb-5">
        <Col lg={10}>
          <div className="text-center mb-5">
            <h1 className="display-5 fw-bold mb-4">
              About Stone Cold Graphics
            </h1>
            <p className="lead text-muted mb-0">
              Crafting premium vinyl graphics and custom decals since 2024
            </p>
          </div>
        </Col>
      </Row>

      {/* Our Story */}
      <Row className="mb-5">
        <Col>
          <h2 className="mb-4">Our Story</h2>
          <p>
            Stone Cold Graphics was founded in 2024 with a simple mission: to
            provide high-quality, custom vinyl graphics at affordable prices.
          </p>
          <p>
            Built on a foundation of creativity and technical expertise, our
            team combines decades of design experience with cutting-edge
            production techniques. We noticed a gap in the market for truly
            customized, high-quality vinyl graphics that could withstand
            Florida's demanding climate, and Stone Cold Graphics was created to
            fill that need.
          </p>
          <p>
            Today, we continue to operate with the same principles that guided
            us from the beginning: quality materials, exceptional design, and
            outstanding customer service.
          </p>
        </Col>
      </Row>

      {/* Our Values */}
      <Row className="mb-5">
        <Col xs={12}>
          <h2 className="text-center mb-4">Our Values</h2>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="border-0 h-100 shadow-sm">
            <Card.Body className="p-4">
              <div className="text-primary mb-3">
                <FaHistory className="fs-1" />
              </div>
              <Card.Title className="fs-4">Quality First</Card.Title>
              <Card.Text>
                We use only premium vinyl materials that are designed to last
                for years without fading, peeling, or cracking. Every product is
                inspected before shipping to ensure it meets our high standards.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="border-0 h-100 shadow-sm">
            <Card.Body className="p-4">
              <div className="text-primary mb-3">
                <FaUsers className="fs-1" />
              </div>
              <Card.Title className="fs-4">Customer Focused</Card.Title>
              <Card.Text>
                Our clients' satisfaction is our top priority. We work closely
                with each customer to ensure their vision comes to life exactly
                as they imagined, with a responsive design process and attentive
                service.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="border-0 h-100 shadow-sm">
            <Card.Body className="p-4">
              <div className="text-primary mb-3">
                <FaAward className="fs-1" />
              </div>
              <Card.Title className="fs-4">Innovation</Card.Title>
              <Card.Text>
                We continuously explore new techniques, materials, and designs
                to push the boundaries of what's possible with vinyl graphics,
                bringing fresh and exciting options to our customers.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Our Process */}
      <Row className="mb-5">
        <Col xs={12}>
          <h2 className="text-center mb-4">Our Process</h2>
          <div className="border-0 rounded shadow-sm p-4 bg-light">
            <Row>
              <Col md={3} className="text-center mb-4 mb-md-0">
                <div
                  className="border rounded-circle bg-primary text-white d-inline-flex align-items-center justify-content-center mb-3"
                  style={{ width: "60px", height: "60px" }}
                >
                  <span className="fs-4 fw-bold">1</span>
                </div>
                <h5>Design</h5>
                <p className="small mb-0">
                  We create or adapt your design for optimal vinyl production
                </p>
              </Col>
              <Col md={3} className="text-center mb-4 mb-md-0">
                <div
                  className="border rounded-circle bg-primary text-white d-inline-flex align-items-center justify-content-center mb-3"
                  style={{ width: "60px", height: "60px" }}
                >
                  <span className="fs-4 fw-bold">2</span>
                </div>
                <h5>Production</h5>
                <p className="small mb-0">
                  Precision-cut vinyl using state-of-the-art equipment
                </p>
              </Col>
              <Col md={3} className="text-center mb-4 mb-md-0">
                <div
                  className="border rounded-circle bg-primary text-white d-inline-flex align-items-center justify-content-center mb-3"
                  style={{ width: "60px", height: "60px" }}
                >
                  <span className="fs-4 fw-bold">3</span>
                </div>
                <h5>Quality Check</h5>
                <p className="small mb-0">
                  Each piece is inspected to ensure perfect cuts and colors
                </p>
              </Col>
              <Col md={3} className="text-center">
                <div
                  className="border rounded-circle bg-primary text-white d-inline-flex align-items-center justify-content-center mb-3"
                  style={{ width: "60px", height: "60px" }}
                >
                  <span className="fs-4 fw-bold">4</span>
                </div>
                <h5>Delivery</h5>
                <p className="small mb-0">
                  Carefully packaged and shipped to your doorstep
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {/* Our Team */}
      <Row>
        <Col xs={12}>
          <h2 className="text-center mb-4">Our Team</h2>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="border-0 shadow-sm text-center h-100">
            <Card.Img
              variant="top"
              src={images.team.gramStone}
              className="rounded-circle mx-auto mt-4"
              style={{ width: "150px", height: "150px", objectFit: "cover" }}
            />
            <Card.Body>
              <Card.Title>Gram Stone</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Production
              </Card.Subtitle>
              <Card.Text>
                Gram brings technical precision to every project, ensuring that
                our vinyl cutting and application processes deliver flawless
                results every time.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="border-0 shadow-sm text-center h-100">
            <Card.Img
              variant="top"
              src={images.team.ashleyCox}
              className="rounded-circle mx-auto mt-4"
              style={{ width: "150px", height: "150px", objectFit: "cover" }}
            />
            <Card.Body>
              <Card.Title>Ashley Cox</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Sales
              </Card.Subtitle>
              <Card.Text>
                Ashley's customer-focused approach and industry knowledge help
                clients find the perfect graphics solutions for their specific
                needs and budget.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="border-0 shadow-sm text-center h-100">
            <Card.Img
              variant="top"
              src={images.team.jimmieMangione}
              className="rounded-circle mx-auto mt-4"
              style={{ width: "150px", height: "150px", objectFit: "cover" }}
            />
            <Card.Body>
              <Card.Title>Jimmie Mangione</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Design
              </Card.Subtitle>
              <Card.Text>
                With a keen artistic eye and creative vision, Jimmie transforms
                concepts into stunning visual designs that exceed client
                expectations.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutScreen;
