import images from '../config/imageConfig';

/**
 * Gets the appropriate image URL based on product data and availability
 * @param {Object} product - The product object
 * @param {string} type - Image type (main, thumbnail, etc)
 * @param {string} fallbackCategory - Fallback category from imageConfig
 * @return {string} Image URL
 */
export const getProductImage = (product, type = 'main', fallbackCategory = 'vehicleGraphics') => {
  // If the product has an image, use it
  if (product && product.image) {
    return product.image;
  }
  
  // If we have a category-specific fallback, use that
  if (product && product.category && images.carousel[product.category.toLowerCase()]) {
    return images.carousel[product.category.toLowerCase()];
  }
  
  // Use the specified fallback
  return images.carousel[fallbackCategory];
};

/**
 * Helper to determine whether to use S3 or bundled images
 * @param {string} path - Image path or key
 * @param {string} type - Image type/category
 * @return {string} Complete image URL 
 */
export const getImageUrl = (path, type = 'products') => {
  // Check if this is already a complete URL
  if (path && (path.startsWith('http://') || path.startsWith('https://'))) {
    return path;
  }
  
  // Check if we're using S3 for this type
  const useS3 = false; // Toggle this when switching to S3
  
  if (useS3 && path) {
    return images.s3[type](path);
  }
  
  // If we get here, return the path as-is (for bundled images)
  return path;
};