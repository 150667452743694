import { Row, Col, Container, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProductCarousel from "../components/ProductCarousel";

const HomeScreen = () => {
  return (
    <>
      <div className="hero-section py-5 bg-dark text-white mb-5">
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="mb-5 mb-md-0">
              <h1 className="display-4 fw-bold">Stone Cold Graphics</h1>
              <p className="lead my-4">
                Premium custom vinyl graphics and decals for your vehicles, walls, and more. 
                Made with high-quality materials that last for years.
              </p>
              <div className="d-flex flex-column flex-sm-row gap-3">
                <Button 
                  as={Link} 
                  to="/contact" 
                  variant="primary" 
                  size="lg" 
                  className="w-100 w-sm-auto"
                >
                  Contact Us
                </Button>
                <Button 
                  as={Link} 
                  to="/about" 
                  variant="outline-light" 
                  size="lg"
                  className="w-100 w-sm-auto"
                >
                  Learn More
                </Button>
              </div>
            </Col>
            <Col md={6}>
              <ProductCarousel />
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <h2 className="text-center mb-5">Why Choose Us</h2>
        <Row className="mb-5">
          <Col md={4}>
            <Card className="h-100 border-0 shadow-sm">
              <Card.Body className="text-center p-4">
                <div className="mb-3">
                  <i className="bi bi-star-fill fs-1 text-primary"></i>
                </div>
                <Card.Title>Premium Quality</Card.Title>
                <Card.Text>
                  Our vinyl graphics are made with premium materials that resist fading and peeling.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="h-100 border-0 shadow-sm">
              <Card.Body className="text-center p-4">
                <div className="mb-3">
                  <i className="bi bi-tools fs-1 text-primary"></i>
                </div>
                <Card.Title>Custom Designs</Card.Title>
                <Card.Text>
                  Get exactly what you want with our custom design services for any project.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="h-100 border-0 shadow-sm">
              <Card.Body className="text-center p-4">
                <div className="mb-3">
                  <i className="bi bi-truck fs-1 text-primary"></i>
                </div>
                <Card.Title>Fast Shipping</Card.Title>
                <Card.Text>
                  Quick production and shipping to get your graphics delivered fast.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <div className="text-center my-5 py-5">
          <h2 className="mb-4">Ready to Transform Your Space?</h2>
          <p className="lead mb-4">
            Get in touch with us today to discuss your graphic design needs.
          </p>
          <Button 
            as={Link} 
            to="/contact" 
            variant="primary" 
            size="lg"
          >
            Contact Us
          </Button>
        </div>
      </Container>
    </>
  );
};

export default HomeScreen;