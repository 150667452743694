import { Container, Row, Col } from "react-bootstrap";

const PrivacyPolicyScreen = () => {
  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col lg={10}>
          <h1 className="mb-4">Privacy Policy</h1>
          <p className="text-muted">Last updated: March 4, 2025</p>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">1. Introduction</h2>
            <p>
              Stone Cold Graphics LLC ("we," "our," or "us") respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or make purchases from us.
            </p>
            <p>
              Please read this Privacy Policy carefully. By accessing or using our website, you acknowledge that you have read, understood, and agree to be bound by all the terms of this Privacy Policy. If you do not agree with our policies, please discontinue use of our website immediately.
            </p>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">2. Information We Collect</h2>
            <h3 className="h5 mb-2">Personal Information</h3>
            <p>
              We may collect personal information that you voluntarily provide to us when you:
            </p>
            <ul>
              <li>Create an account</li>
              <li>Place an order</li>
              <li>Sign up for our newsletter</li>
              <li>Contact us with inquiries</li>
              <li>Participate in promotions or surveys</li>
            </ul>
            <p>
              The personal information we may collect includes:
            </p>
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Shipping and billing address</li>
              <li>Payment information (processed securely through our payment processors)</li>
            </ul>
            
            <h3 className="h5 mb-2 mt-3">Automatically Collected Information</h3>
            <p>
              When you visit our website, we may automatically collect certain information about your device, including:
            </p>
            <ul>
              <li>IP address</li>
              <li>Browser type</li>
              <li>Device type</li>
              <li>Pages visited</li>
              <li>Time spent on pages</li>
              <li>Referring website</li>
              <li>Other browsing information</li>
            </ul>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">3. How We Use Your Information</h2>
            <p>
              We may use the information we collect for various purposes, including to:
            </p>
            <ul>
              <li>Process and fulfill your orders</li>
              <li>Create and manage your account</li>
              <li>Send order confirmations and updates</li>
              <li>Respond to your inquiries and provide customer support</li>
              <li>Send marketing communications (with your consent)</li>
              <li>Improve our website and services</li>
              <li>Analyze usage patterns and trends</li>
              <li>Protect against unauthorized access and fraud</li>
              <li>Comply with legal obligations</li>
            </ul>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">4. Cookies and Tracking Technologies</h2>
            <p>
              We use cookies and similar tracking technologies to track activity on our website and hold certain information. Cookies are small files placed on your device that allow us to enhance your browsing experience.
            </p>
            <p>
              You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our site.
            </p>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">5. Sharing Your Information</h2>
            <p>
              We may share your information with:
            </p>
            <ul>
              <li>Service providers who assist us in operating our website and conducting our business (shipping companies, payment processors, etc.)</li>
              <li>Legal authorities when required by law or to protect our rights</li>
              <li>Business partners with your consent</li>
            </ul>
            <p>
              We do not sell, rent, or trade your personal information to third parties for marketing purposes.
            </p>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">6. Data Security</h2>
            <p>
              We implement reasonable security measures to protect your personal information. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
            </p>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">7. Your Rights</h2>
            <p>
              Depending on your location, you may have certain rights regarding your personal information, including:
            </p>
            <ul>
              <li>Right to access your personal information</li>
              <li>Right to correct inaccurate information</li>
              <li>Right to delete your information</li>
              <li>Right to restrict processing</li>
              <li>Right to data portability</li>
              <li>Right to object to processing</li>
            </ul>
            <p>
              To exercise these rights, please contact us using the information provided in the "Contact Us" section.
            </p>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">8. Children's Privacy</h2>
            <p>
              Our website is not intended for children under 18 years of age. We do not knowingly collect personal information from children under 18. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us.
            </p>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">9. Changes to This Privacy Policy</h2>
            <p>
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top.
            </p>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">10. Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, please contact us at:
            </p>
            <p>
              Email: gram.stone@stonecold.graphics<br />
              Phone: (813) 525-6820
            </p>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicyScreen;