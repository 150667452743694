import { Container, Row, Col } from "react-bootstrap";

const ShippingPolicyScreen = () => {
  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col lg={10}>
          <h1 className="mb-4">Shipping Policy</h1>
          <p className="text-muted">Last updated: March 4, 2025</p>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">1. Processing Time</h2>
            <p>
              At Stone Cold Graphics LLC, we're committed to delivering your vinyl graphics and decals as quickly as possible while maintaining our high-quality standards.
            </p>
            <ul>
              <li><strong>Standard Products:</strong> Orders for in-stock standard products typically process within 3-5 business days.</li>
              <li><strong>Custom Designs:</strong> Custom orders require additional time for design, production, and quality checks. Processing time is typically 5-7 business days but may vary based on complexity and current order volume.</li>
              <li><strong>Large Volume Orders:</strong> Orders exceeding 10 items or covering more than 20 square feet may require additional processing time. We'll provide an estimated timeline during the ordering process.</li>
            </ul>
            <p>
              Processing time does not include weekends or holidays. Orders placed after 2:00 PM EST will begin processing the following business day.
            </p>
          </section>
          
          
          <section className="mb-4">
            <h2 className="h4 mb-3">2. Order Tracking</h2>
            <p>
              To check the status of your order or get tracking information, please contact our customer service team:
            </p>
            <ul>
              <li>By phone: (813) 525-6820</li>
              <li>By email: info@stonecold.graphics</li>
            </ul>
            <p>
              Please have your order number ready when contacting us. Our team will provide you with the most up-to-date information on your order's status and estimated delivery time.
            </p>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">3. Shipping Destinations</h2>
            <p>
              We currently ship to:
            </p>
            <ul>
              <li>Contiguous United States (48 states, excluding Alaska and Hawaii)</li>
            </ul>
            <p>
              At this time, we do not ship to Alaska, Hawaii, U.S. territories, or international destinations. We apologize for any inconvenience this may cause.
            </p>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">4. Delivery Issues</h2>
            <p>
              In the rare event of delivery issues:
            </p>
            <ul>
              <li><strong>Lost Packages:</strong> If your tracking information shows no movement for 5+ business days, please contact us. We'll work with the carrier to locate your package or process a replacement.</li>
              <li><strong>Damaged Packages:</strong> If your package arrives damaged, please take photos of the damaged packaging and contents before contacting us within 48 hours of delivery.</li>
              <li><strong>Incorrect Address:</strong> Please ensure your shipping address is correct at checkout. We are not responsible for deliveries sent to addresses provided by customers.</li>
            </ul>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">5. Package Protection</h2>
            <p>
              We take great care in packaging your items to ensure they arrive in perfect condition:
            </p>
            <ul>
              <li>Vinyl graphics and decals are packaged in protective sleeves</li>
              <li>Items are secured in rigid mailers or boxes to prevent bending or damage</li>
              <li>Fragile or large items receive additional protective packaging</li>
            </ul>
          </section>
          
          
          <section className="mb-4">
            <h2 className="h4 mb-3">6. Contact Information</h2>
            <p>
              If you have any questions about our Shipping Policy, please contact our customer service team:
            </p>
            <p>
              Email: gram.stone@stonecold.graphics<br />
              Phone: (813) 525-6820
            </p>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default ShippingPolicyScreen;