import { Carousel, Image } from "react-bootstrap";
import Loader from "./Loader";
import Message from "./Message";
import { useGetProductsQuery } from "../slices/productsApiSlice";
import images from "../config/imageConfig";
import { getProductImage } from "../utils/imageUtils";

// Fallback carousel data (used when API fails or is loading)
const fallbackItems = [
  {
    _id: 1,
    image: images.carousel.vehicleGraphics,
    name: "Custom Vehicle Graphics",
    description: "Transform your vehicle with premium vinyl graphics that make a lasting impression. Our high-quality materials ensure durability in all weather conditions."
  },
  {
    _id: 2,
    image: images.carousel.wallDecals,
    name: "Wall Decals & Murals",
    description: "Bring your walls to life with custom vinyl graphics that transform any space. Perfect for homes, offices, retail spaces, and more."
  },
  {
    _id: 3,
    image: images.carousel.businessSignage,
    name: "Business Signage & Branding",
    description: "Make your business stand out with professional signage and graphics. From storefronts to fleet vehicles, we've got you covered."
  }
];

const ProductCarousel = () => {
  const { data: products, isLoading, error } = useGetProductsQuery();
  
  // Determine which data to use
  const carouselItems = !isLoading && !error && products ? products : fallbackItems;

  return (
    <div className="carousel-container" style={{ 
      borderRadius: '12px', 
      overflow: 'hidden',
      boxShadow: '0 10px 20px rgba(0,0,0,0.15)',
      border: '1px solid rgba(255,255,255,0.1)'
    }}>
      {isLoading ? (
        <div style={{ height: '400px', background: '#212529', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Loader />
        </div>
      ) : error ? (
        <div style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
          <Message variant="danger">
            {error?.data?.message || error.error || 'Failed to load products'}
          </Message>
        </div>
      ) : (
        <Carousel pause="hover" className="bg-dark">
          {carouselItems.map((item) => (
            <Carousel.Item key={item._id}>
              <Image 
                src={getProductImage(item, 'main', 'vehicleGraphics')} 
                alt={item.name} 
                fluid 
                className="d-block w-100 carousel-image"
                style={{ 
                  height: '400px', 
                  objectFit: 'cover',
                  opacity: '0.85' 
                }}
              />
              <Carousel.Caption 
                className="carousel-caption px-4 py-2 rounded mb-4" 
                style={{ 
                  backgroundColor: 'rgba(0,0,0,0.6)', 
                  backdropFilter: 'blur(5px)',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
                  borderLeft: '3px solid var(--bs-primary)',
                  maxWidth: '100%',
                  left: '0',
                  right: '0',
                  margin: '0 auto'
                }}
              >
                <h3 className="fw-bold" style={{ fontSize: 'clamp(1.25rem, 5vw, 1.75rem)' }}>{item.name}</h3>
                <p style={{ fontSize: 'clamp(0.875rem, 4vw, 1rem)', margin: '0.5rem 0' }}>
                  {item.description ? (item.description.substring(0, 80) + '...') : ''}
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default ProductCarousel;