// Import bundled images
import vehicleGraphics from '../assets/images/carousel/vehicle-graphics.jpg';
import wallDecals from '../assets/images/carousel/wall-decals.jpg';
import businessSignage from '../assets/images/carousel/business-signage.jpg';
import gramStone from '../assets/images/team/gram-stone.jpg';
import ashleyCox from '../assets/images/team/ashley-cox.jpg';
import jimmieMangione from '../assets/images/team/jimmie-mangione.jpg';
import logo from '../assets/logo.png';

// Default S3 bucket URL (for future use)
const S3_BUCKET_URL = 'https://stonecoldgraphics.s3.amazonaws.com';

// Configure image sources
const images = {
  // Bundled static images
  logo,
  team: {
    gramStone,
    ashleyCox,
    jimmieMangione
  },
  
  // Carousel images - bundled for now, can be switched to S3 URLs later
  carousel: {
    vehicleGraphics,
    wallDecals,
    businessSignage
  },
  
  // Example of how to configure S3 URLs for future use
  s3: {
    products: (filename) => `${S3_BUCKET_URL}/products/${filename}`,
    carousel: (filename) => `${S3_BUCKET_URL}/carousel/${filename}`,
  }
};

export default images;