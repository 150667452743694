import { Container, Row, Col } from "react-bootstrap";

const TermsOfServiceScreen = () => {
  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col lg={10}>
          <h1 className="mb-4">Terms of Service</h1>
          <p className="text-muted">Last updated: March 9, 2025</p>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">1. Introduction</h2>
            <p>
              Welcome to Stone Cold Graphics LLC. These Terms of Service ("Terms") govern your use of our website, products, and services (collectively, the "Services").
            </p>
            <p>
              By accessing or using our Services, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, you may not access or use our Services.
            </p>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">2. Product Orders and Payments</h2>
            <p>
              By placing an order through our website, you agree to:
            </p>
            <ul>
              <li>Provide accurate and complete payment information</li>
              <li>Pay all charges incurred through your account at the prices in effect when the charges are incurred</li>
              <li>Verify all product specifications, dimensions, and designs before finalizing your order</li>
              <li>Acknowledge that colors displayed on your screen may vary slightly from the final printed product due to monitor calibration and material differences</li>
            </ul>
            <p>
              All prices are subject to change without notice. We reserve the right to refuse or cancel orders for any reason, including pricing or typographical errors.
            </p>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">3. Custom Designs and Intellectual Property</h2>
            <p>
              When submitting custom designs or content for production:
            </p>
            <ul>
              <li>You represent and warrant that you own or have proper license to use all intellectual property contained in your designs</li>
              <li>You grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, and modify your designs solely for the purpose of producing your order</li>
              <li>We reserve the right to refuse any design that we believe violates copyright, trademark, or other intellectual property rights</li>
              <li>We may refuse designs that contain offensive, obscene, or inappropriate content at our sole discretion</li>
            </ul>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">4. Production and Shipping</h2>
            <p>
              Regarding the production and delivery of orders:
            </p>
            <ul>
              <li>Production times vary based on product type, customization requirements, and current order volume</li>
              <li>Shipping estimates are provided in good faith but are not guaranteed</li>
              <li>We are not responsible for delays caused by shipping carriers, customs, or other circumstances beyond our control</li>
              <li>Risk of loss and title for items purchased passes to you upon delivery to the carrier</li>
            </ul>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">5. Returns and Refunds</h2>
            <p>
              Our return and refund policies are outlined in our separate Return Policy, which is incorporated by reference into these Terms.
            </p>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">6. Limitation of Liability</h2>
            <p>
              To the maximum extent permitted by law:
            </p>
            <ul>
              <li>Our Services are provided "as is" without warranties of any kind, either express or implied</li>
              <li>We shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of our Services</li>
              <li>Our total liability to you for any claim arising from or related to our Services shall not exceed the amount paid by you for the specific product or service giving rise to such claim</li>
            </ul>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">7. Indemnification</h2>
            <p>
              You agree to indemnify, defend, and hold harmless Stone Cold Graphics LLC, its officers, directors, employees, agents, and suppliers from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to:
            </p>
            <ul>
              <li>Your violation of these Terms</li>
              <li>Your use of our Services</li>
              <li>Your violation of any rights of a third party</li>
              <li>Your violation of any applicable laws or regulations</li>
            </ul>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">8. Governing Law and Jurisdiction</h2>
            <p>
              These Terms shall be governed by and construed in accordance with the laws of the State of Florida, without regard to its conflict of law provisions. You agree to submit to the personal and exclusive jurisdiction of the courts located within Hillsborough County, Florida.
            </p>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">9. Changes to Terms</h2>
            <p>
              We reserve the right to modify these Terms at any time. We will provide notice of material changes by posting the amended Terms on our website. Your continued use of our Services after such changes constitutes your acceptance of the modified Terms.
            </p>
          </section>
          
          <section className="mb-4">
            <h2 className="h4 mb-3">10. Contact Information</h2>
            <p>
              If you have any questions about these Terms, please contact us at:
            </p>
            <p>
              Email: gram.stone@stonecold.graphics<br />
              Phone: (813) 525-6820
            </p>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsOfServiceScreen;